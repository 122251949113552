<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="loading">

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{ $t("Загрузка...") }}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text v-else-if="routes" class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>
                        <v-list class="modal-select-list" dense outlined>

                            <v-list-item-group
                                v-model="selected"
                                color="cyan darken-1"
                            >
                                <v-list-item
                                    v-for="(route, i) in routes"
                                    :key="i"
                                    :value="route"
                                >
                                    <!-- Иконка маршрута -->
                                    <v-list-item-avatar>
                                        <v-icon
                                        >
                                            fas fa-random
                                        </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>

                                        <v-list-item-title v-text="route.name.toUpperCase()"></v-list-item-title>

                                        <v-list-item-subtitle>
                                            {{ `${$t("Версия")} ${route.version} ${$t("от_время")} ${route.created}` }}
                                        </v-list-item-subtitle>

                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                            
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-text v-else class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>
                        Ошибка загрузки списка схем бизнес-процессов
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="selected"
                >
                    {{$t("Отправить")}}
                </v-btn>

                <v-btn 
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{$t("Отмена")}}
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "StartProcessDlg",
    data: () => ({
        title: "Схемы_бизнес-процессов",
        options: {
            color: 'grey lighten-3',
            width: 400,
            zIndex: 200,
            noconfirm: false,
        },
    }),
    computed: {
        ...mapGetters('dialogs/startProcess', { visible: 'isVisible', loading: 'isLoading', routes: 'getRoutes' }),
        selected: {
            get: function() {
                return this.$store.getters['dialogs/startProcess/getSelectedRoute'];
            },
            set: function(v) {
                this.$store.commit('dialogs/startProcess/SET_SELECTED_ROUTE', v);
            }
        }
    },
    methods: {
        ...mapActions('dialogs/startProcess', [ 'ok', 'cancel' ]),
    }
}
</script>